@font-face {
    font-family: 'RedditSans';
    src: url('./RedditSans-Regular.woff2') format('woff2'),
        url('./RedditSans-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'RedditSans';
    src: url('./RedditSans-ExtraBold.woff2') format('woff2'),
        url('./RedditSans-ExtraBold.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'RedditSans';
    src: url('./RedditSans-SemiBoldItalic.woff2') format('woff2'),
		url('./RedditSans-SemiBoldItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'RedditSans';
    src: url('./RedditSans-BoldItalic.woff2') format('woff2'),
        url('./RedditSans-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'RedditSans';
    src: url('./RedditSans-Light.woff2') format('woff2'),
        url('./RedditSans-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'RedditSans';
    src: url('./RedditSans-SemiBold.woff2') format('woff2'),
        url('./RedditSans-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'RedditSans';
    src: url('./RedditSans-Bold.woff2') format('woff2'),
        url('./RedditSans-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'RedditSans';
    src: url('./RedditSans-ExtraBoldItalic.woff2') format('woff2'),
        url('./RedditSans-ExtraBoldItalic.woff') format('woff');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'RedditSans';
    src: url('./RedditSans-SemiBold.woff2') format('woff2'),
        url('./RedditSans-SemiBold.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'RedditSans';
    src: url('./RedditSans-Italic.woff2') format('woff2'),
        url('./RedditSans-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'RedditSans';
    src: url('./RedditSans-LightItalic.woff2') format('woff2'),
        url('./RedditSans-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'RedditSans';
    src: url('./RedditSans-SemiBoldItalic.woff2') format('woff2'),
        url('./RedditSans-SemiBoldItalic.woff') format('woff');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}